import React from 'react';
import './ReportPage.css';
import { MapChart } from '../../../Charts/Map/MapChart';
import { DonutChart } from '../../../Charts/Donut/DonutChart';
import { BarChart } from '../../../Charts/Bar/BarChart';
import { WordCloud } from '../../../Charts/WordCloud/WordCloud';
import { AreaChart } from '../../../Charts/Area/AreaChart';

 export function ReportPage({title, subtitle, chart, component, hideLegend, pageNumber}) {

  
    return (<div dataIndex={pageNumber} className='report-page'>
        <div className='report-page-block'>
        <div className='report-page-header'>
            <b>{title}</b>
           {subtitle && <p>{subtitle}</p>}
        </div>
        {!component ? <div className='report-page-body'>
            <div className='report-body-col-1'>
                {chart.type === 'donut' && <DonutChart dataPoints={chart.data} />}
                {chart.type === 'bar' && <BarChart dataPoints={chart.data} />}
                {chart.type === 'map' && <MapChart data={chart.data} />}
                {chart.type === 'wordcloud' && <WordCloud words={chart.data} />}
                {chart.type === 'area' && <AreaChart dataPoints={chart.data} />}


            </div>
            <div className='report-body-col-2'>
                <div className='report-body-col-row-1' style={{background: hideLegend ? 'transparent' : '#fffbea'}}>
                    {!hideLegend && chart.data.map((data) => {
                        return (
                            <div className='report-legend'>
                                <div className='legend-square' style={{backgroundColor: data.color}}></div>
                                <p>{data.y ? data.y : data.value} - </p>
                                <p className='report-legend-label'>{data.label ? data.label : data.text}</p>
                            </div>
                        )
                    })

                    }
                </div>
                <div className='report-body-col-row-2'>
                    <div className='page-number'>
                        {pageNumber}
                    </div>
                </div>
            </div>
        </div>
                : <div className='report-page-body'>
                   {component}
                </div>}

        </div>
    </div>);
  }
  
  export default ReportPage;