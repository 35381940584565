import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import './DropdownConfigUser.css';
import { fetchUser } from "../../services/api/fetchUser";

function DropdownConfigUser({ dropdownRef }) {
  const [userData, setUserData] = useState(null);
  const { clientId } = useParams();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await fetchUser();
        setUserData(user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserData();
  }, []);

  if (!userData) {
    return null;
  }

  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.clear();
    if (userData.profile === 'cliente' || userData.profile === 'cliente_admin') {
      window.location.href = `${process.env.REACT_APP_LINK_URL}seguranca/sair/`;
    } else {
      window.location.href = process.env.REACT_APP_LINK_URL;
    }
  };

  return (
    <div className="div-configUser" ref={dropdownRef}>
      <div className="div-2-configUser">
        <div className="div-3-configUser">{userData.name}</div>
        <div className="div-4-configUser">código de cliente: {userData.id}</div>
      </div>
      <hr className="hr-horizontal-configUser"></hr>
      <div className="div-5-configUser"></div>
      <div className="div-6-configUser">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/638838ce42281f346182a1f2c95cec4cbbb707b5b03034343d762462dd4aaead?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
          className="img-configUser"
          alt="Edit"
        />
        <a href={'/' + clientId + '/teladadosusuario'} className="div-7-configUser">Editar Dados</a>
      </div>
      <div className="div-8-configUser">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6a07a740b2bdd607c426a4d45dfe640137d25be91904b419083259f73d3bf53?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
          className="img-configUser"
          alt="Edit"
        />
        <a href={'/' + clientId + '/teladadosusuario'} className="div-9-configUser">Alterar Senha</a>
      </div>
      <hr className="hr-horizontal-configUser"></hr>
      <div className="div-11-configUser">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b41cbbeae06e63b7cf979a8df6f850e50d512b44dc9befe87260f36fd286e0b6?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
          className="img-configUser"
          alt="Edit"
        />
        <a onClick={handleLogout} className="div-12-configUser">Sair</a>
      </div>
    </div>
  );
}

export default DropdownConfigUser;
