import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import './RelatorioAnalitico.css';
import { Svg } from '../../../assets/report-icons/svg';
import { ReportPage } from './ReportPage/ReportPage';
import DonutChart from '../../Charts/Donut/DonutChart';
import BarChart from '../../Charts/Bar/BarChart';
import { MockReportData } from '../../../constants/reports.constants';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const RelatorioAnalitico = forwardRef(({ reportData, startDate, endDate }, ref) => {
    const [hideDivider, setHideDivider] = useState(false);
    const contentRef = useRef(ref);

    useImperativeHandle(ref, () => ({
        handlePrint
    }));
    
    const waitForAnimationEnd = (element) => {
        return new Promise((resolve) => {
            element.addEventListener('animationend', resolve, { once: true });
        });
    };

    const waitForRenderComplete = (element, timeout = 2000) => {
        return new Promise((resolve) => {
            const observer = new MutationObserver(() => {
                clearTimeout(checkTimeout);
                checkTimeout = setTimeout(() => {
                    observer.disconnect();
                    resolve();
                }, timeout);
            });
    
            let checkTimeout = setTimeout(() => {
                observer.disconnect();
                resolve();
            }, timeout);
    
            observer.observe(element, {
                attributes: true,
                childList: true,
                subtree: true
            });
        });
    };

    const handlePrint = async () => {
        setHideDivider(true);
        const element = contentRef.current;

        const pdf = new jsPDF({
            orientation: 'portrait',
            format: 'a4',
            compress: true,
        });

        const pageElements = Array.from(element.getElementsByClassName('report-page'));

        await waitForRenderComplete(element);
        //  Promise.all(pageElements.map(waitForAnimationEnd));

        const addPageToPDF = (canvas, pageNumber) => {
            const imgData = canvas.toDataURL('image/png');
            pageNumber > 1 && pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        };

        const promises = pageElements.map(page => {
            const pageIndex = page.getAttribute('data-index'); 
            return html2canvas(page).then(canvas => ({
                canvas,
                pageIndex: parseInt(pageIndex, 10)
            }));
        });

        Promise.all(promises).then(pages => {
            pages.sort((a, b) => a.pageIndex - b.pageIndex);

            pages.forEach((page, index) => {
                addPageToPDF(page.canvas, index + 1);
            });

            pdf.save('Relatorio de Análise Crítica.pdf');
            setHideDivider(false);

        });
    };

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const title = "Relatório de Análise Crítica";

    const startDateFormatted = startDate ? startDate.toLocaleDateString('pt-BR') : '';
    const endDateFormatted = endDate ? endDate.toLocaleDateString('pt-BR') : '';
    const periode = `${startDateFormatted} - ${endDateFormatted}`;

    const client = reportData.client;
    const category = reportData.category;
    const totalNews = reportData.total;

    const mediaType = reportData?.source_types?.[0]?.source_type ? 
    (reportData.source_types[0].source_type.charAt(0).toUpperCase() + reportData.source_types[0].source_type.slice(1)) : 
    'N/A';

    const mediaName = reportData?.clippings_by_source?.[0]?.name || 'N/A';

    const qualitativeColors = {
        'POSITIVA': '#005973',
        'NEUTRA': '#ffc20e',
        'NEGATIVA': '#ee3124'
    };

    const reviewQualityPage = {
        type: 'donut',
        data: reportData.qualitative.map(item => ({
            y: item.total,
            label: item.value.charAt(0) + item.value.slice(1).toLowerCase(),
            color: qualitativeColors[item.value.toUpperCase()] || getRandomColor()
        }))
    };

    const reviewTextTypePage = {
        type: 'donut',
        data: reportData.media_content_types.map(item => ({
            y: item.total,
            label: item.media_content_type || 'Indefinido',
            color: getRandomColor()
        }))
    };

    const reviewMentionPage = {
        type: 'donut',
        data: reportData.mention.map(item => ({
            y: item.total,
            label: item.value.charAt(0) + item.value.slice(1).toLowerCase(),
            color: qualitativeColors[item.value.toUpperCase()] || getRandomColor()
        }))
    };

    const reviewWordCloudPage = {
        type: 'wordcloud',
        data: reportData.tags.map(tag => ({
            value: tag.total,
            text: tag.value,
            color: getRandomColor()
        }))
    };

    const reviewMediaDistributionPage = {
        type: 'donut',
        data: reportData.source_types.map(source => ({
            y: source.total,
            label: source.source_type.charAt(0).toUpperCase() + source.source_type.slice(1),
            color: getRandomColor()
        }))
    };

    const reviewOcupedSpacePage = {
        data: [
            { type: 'TV', value: reportData.tv_occupied_space !== null ? reportData.tv_occupied_space : 0 },
            { type: 'Rádio', value: reportData.radio_occupied_space !== null ? reportData.radio_occupied_space : 0 },
            { type: 'Web', value: reportData.web_occupied_space !== null ? `${reportData.web_occupied_space} centímetros` : '0 centímetros' },
            { type: 'Impresso', value: reportData.newspaper_occupied_space !== null ? `${reportData.newspaper_occupied_space} centímetros` : '0 centímetros' }
        ]
    };

    const reviewQualityOcupedSpacePage = {
        data: [
            {
                type: 'TV',
                data: (reportData.source_qualitative?.TV || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            },
            {
                type: 'WEB',
                data: (reportData.source_qualitative?.WEB || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            },
            {
                type: 'Impresso',
                data: (reportData.source_qualitative?.Impresso || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            },
            {
                type: 'Rádio',
                data: (reportData.source_qualitative?.Radio || []).map(item => ({
                    y: item.y,
                    label: item.label,
                    color: qualitativeColors[item.label]
                }))
            }
        ]
    };

    const reviewMediaNewsPage = {
        type: 'bar',
        data: reportData.clippings_by_source.map(item => ({
            y: item.total,
            label: item.name,
            color: getRandomColor()
        }))
    };

    const reviewMediaValuePage = {
        type: 'bar',
        data: reportData.clippings_by_marketing_value
            .map(item => ({
                y: item.total != null ? item.total : 0, // Substituir valores nulos por 0
                label: item.source || 'Unknown', // Substituir valores nulos em source por 'Unknown'
                color: getRandomColor()
            }))
    };

    const reviewMediaPublishDataPage = {
        type: 'area',
        data: reportData.clippings_by_date
            .filter(item => item.date && item.total != null) // Filtrar itens com date e total válidos
            .map(item => ({
                x: new Date(item.date),
                y: item.total
            }))
    };

    const reviewMapPage = {
        type: 'map',
        data: reportData.states.map(item => ({
            label: item.state,
            value: item.total,
            color: getRandomColor()
        }))
    };

    const swotColors = {
        'Força': '#0000FF',       
        'Oportunidade': '#008000', 
        'Ameaça': '#FF0000',       
        'Fraqueza': '#000000'      
    };
    
    const reviewSwotPage = {
        type: 'donut',
        data: reportData.swot.map(item => ({
            y: item.total,
            label: item.value.charAt(0).toUpperCase() + item.value.slice(1).toLowerCase(),
            color: swotColors[item.value] || getRandomColor()
        }))
    };

    const initialVehiclePageNumber = reportData.swot && reportData.swot.length > 0 ? 15 : 14

    const maxRowsPerPage = 35; // Número máximo de linhas por página
    const totalPages = Math.ceil(reportData.sources.length / maxRowsPerPage);

    const getTableRowsForPage = (pageIndex) => {
        const startIndex = pageIndex * maxRowsPerPage;
        return reportData.sources.slice(startIndex, startIndex + maxRowsPerPage);
    };

    return (
        <>
            <div ref={contentRef} className='report-container'>
                <div className='report-box'>
                    <div className='report-page' data-index={1}>
                        <div className='report-page-block-initial'>
                            <div className='initial-page-header'></div>
                            <div className='initial-page-header-line'></div>
                            <div className='initial-page-text'>
                                <h1 className='initial-page-h'>{title}</h1>
                                <h4 className='initial-page-data'>{periode}</h4>
                                <h2 className='initial-page-h'>{client}</h2>
                                {reportData.category && (
                                <h4 className='initial-page-h'>{category}</h4>
                                )}
                            </div>
                            <div className='initial-page-footer-line'></div>
                            <div className='initial-page-footer'></div>
                        </div>
                    </div>

                    {!hideDivider && <div className='divider'></div>}

                    <div className='report-page' data-index={2}>
                        <div className='summary-header'>
                            <p id="totalNewsText">TOTAL DE NOTÍCIAS</p>
                            <p id="totalNewsNumber">{totalNews}</p>
                        </div>
                        <div className='summary-body'>
                            <div className='summary-body-column1'>
                                <div className='summary-col-1-row-1'>
                                    <div>
                                        <p>Mídia com maior número de notícias:</p>
                                        <b>{mediaType}</b>
                                    </div>
                                </div>
                                <div className='summary-col-1-row-2'>
                                    <div>
                                        <p>Veículo com maior número de notícias:</p>
                                        <b>{mediaName}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='summary-body-column2'>
                                <p>Conteúdo:</p>
                                <ul>
                                    <li>Análise qualitativa das matérias</li>
                                    <li>Notícias por tipo de texto</li>
                                    <li>Direta/Indireta</li>
                                    <li>Assunto</li>
                                    <li>Distribuição por mídia</li>
                                    <li>Espaço ocupado na mídia</li>
                                    <li>Análise qualitativa do espaço ocupado</li>
                                    <li>Notícias por veículo</li>
                                    <li>Valoração por veículo</li>
                                    <li>Notícias por data de publicação</li>
                                    <li>Notícias por estado</li>
                                </ul>
                            </div>
                        </div>
                        <div className='summary-footer'></div>
                    </div>

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Análise qualitativa das matérias'}
                        subtitle={'Volume de notícias classificadas por polaridade.'}
                        chart={reviewQualityPage}
                        pageNumber={3}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Notícias por tipo de texto'}
                        subtitle={'Volume de notícias classificadas por tipo de texto.'}
                        chart={reviewTextTypePage}
                        pageNumber={4}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Direta/Indireta'}
                        subtitle={'Volume de notícias por menções direta/indireta.'}
                        chart={reviewMentionPage}
                        pageNumber={5}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Assunto'}
                        subtitle={'Nuvem com os assuntos mais noticiados.'}
                        chart={reviewWordCloudPage}
                        pageNumber={6}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Distribuição por mídia'}
                        subtitle={'Volume de notícias por tipo de mídia.'}
                        chart={reviewMediaDistributionPage}
                        pageNumber={7}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Espaço ocupado na mídia'}
                        component={(
                            <>
                                <div className='report-body-col-1'>
                                    <div className='media-distribution'>
                                        {reviewOcupedSpacePage.data.map((data) => {
                                            return (
                                                <div key={data.type}>
                                                    <img className='icons-media' src={Svg[data.type.normalize('NFD').replace(/\p{Mn}/gu, "").toLowerCase()]} alt={data.type} />
                                                    <p className='media-type-label'>{data.type + ": "}</p> <p className='media-type-label'>{data.value}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='report-body-col-2'>
                                    <div className='report-body-col-row-1' style={{ background: 'transparent' }}></div>
                                    <div className='report-body-col-row-2'>
                                        <div className='page-number'>{8}</div>
                                    </div>
                                </div>
                            </>
                        )}
                        pageNumber={8}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Análise qualitativa do espaço ocupado'}
                        subtitle={' '}
                        component={(
                            <>
                                <div className='report-body-col-1'>
                                    <div className='space-media-container'>
                                        {reviewQualityOcupedSpacePage.data.map((chart) => {
                                            return ( chart.data.length > 0 &&
                                                <div className='space-media' key={chart.type}>
                                                    <img className='icons-media-space' src={Svg[chart.type.normalize('NFD').replace(/\p{Mn}/gu, "").toLowerCase()]} alt={chart.type} />
                                                    <DonutChart style={{ top: '60px' }} fontSize={10} width={200} height={150} innerRadius={40} dataPoints={chart.data} hideLabel={true} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='report-body-col-2'>
                                    <div className='report-body-col-row-1' style={{ background: '#fffbea' }}>
                                        {reviewQualityOcupedSpacePage.data.map((chart) => {
                                            return ( chart.data.length > 0 &&
                                                <div key={chart.type} style={{ marginBottom: '10px' }}>
                                                    <b className='chart-type'>{chart.type}</b>
                                                    {chart.data.map((data) => {
                                                        return (
                                                            <div className='report-legend' key={data.label}>
                                                                <div className='legend-square' style={{ backgroundColor: data.color }}></div>
                                                                <p>{data.y} - </p>
                                                                <p className="report-legend-label">{data.label}</p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className='report-body-col-row-2'>
                                        <div className='page-number'>{9}</div>
                                    </div>
                                </div>
                            </>
                        )}
                        pageNumber={9}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Notícias por veículo'}
                        subtitle={'Ranking com 10 veículos de imprensa que mais noticiaram.'}
                        chart={reviewMediaNewsPage}
                        pageNumber={10}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Valoração por veículo'}
                        subtitle={'Ranking com 10 veículos de imprensa com maior valor publicitário.'}
                        chart={reviewMediaValuePage}
                        component={(
                            <div className='report-page-body'>
                                <div className='report-body-col-1'>
                                    <BarChart dataPoints={reviewMediaValuePage.data.filter(item => item.y > 0)} />
                                    <div className='total-media-values'>
                                    <p>{'Total: '}<b>{reviewMediaValuePage.data.reduce((total, item) => {
                                        return total + item.y;
                                        }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p>
                                </div>
                                </div>
                                <div className='report-body-col-2'>
                                    <div className='report-body-col-row-1' style={{ background: '#fffbea' }}>
                                        {reviewMediaValuePage.data.filter(item => item.y > 0).map((data) => {
                                            return (
                                                <div className='report-legend' key={data.label}>
                                                    <div className='legend-square' style={{ backgroundColor: data.color }}></div>
                                                    <p>{data.y.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                </div>
                                            );
                                        })}
                                        
                                    </div>
                                    <div className='report-body-col-row-2'>
                                        <div className='page-number'>{11}</div>
                                    </div>
                                </div>
                               
                            </div>
                        )}
                        pageNumber={11}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Notícias por data de publicação'}
                        subtitle={'Volume de notícias por data de publicação.'}
                        chart={reviewMediaPublishDataPage}
                        hideLegend={true}
                        pageNumber={12}
                    />

                    {!hideDivider && <div className='divider'></div>}

                    <ReportPage
                        title={'Notícias por estado'}
                        subtitle={'Volume de notícias classificadas por estado.'}
                        chart={reviewMapPage}
                        pageNumber={13}
                    />

                    {reportData.swot && reportData.swot.length > 0 && (
                        <>
                            {!hideDivider && <div className='divider'></div>}
                            <ReportPage
                                title={'Fator SWOT'}
                                chart={reviewSwotPage}
                                pageNumber={14}
                            />
                        </>
                    )}

                    {!hideDivider && <div className='divider'></div>}

                    {Array.from({ length: totalPages }).map((_, pageIndex) => {
                        const currentPageNumber = initialVehiclePageNumber + pageIndex;
                        return (
                            <>
                                {!hideDivider && <div className='divider'></div>}
                                <ReportPage
                                    title={`Veículos`}
                                    component={(
                                        <div>
                                            <table className='report-table'>
                                                <thead>
                                                    <tr>
                                                        <th className='table-header-veiculos'>Veículos</th>
                                                        <th className='table-header-dados'>Total</th>
                                                        <th className='table-header-dados'>Positiva</th>
                                                        <th className='table-header-dados'>Neutra</th>
                                                        <th className='table-header-dados'>Negativa</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getTableRowsForPage(pageIndex).map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{row.name}</td>
                                                            <td>{row.positive + row.neutral + row.negative}</td>
                                                            <td>{row.positive}</td>
                                                            <td>{row.neutral}</td>
                                                            <td>{row.negative}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='container-number'>
                                                <div className='page-number'>{currentPageNumber}</div>
                                            </div>
                                        </div>
                                    )}
                                    pageNumber={currentPageNumber}
                                />
                            </>
                        );
                    })}
                </div>
            </div>
        </>
    );
});

export default RelatorioAnalitico;
